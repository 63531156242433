import React, { useState, useEffect } from "react";
import { updateVarian } from "../../../controller/sellerProductController";
import style from "../../../css/sellerProfile/createProduct/sellerProductVarian.module.css";
import { regexNumber } from "../../../utils/Constants";
import useDebounce from "../../../utils/useDebounce";
import { CCurrencyInput, Input } from "../../CustomComponent/StyledComponent";
import Switcher from "../../CustomComponent/Switcher";

function SellerProductDetailCardView({ product_id, data, handleChangeVarian, ...props }) {
  var name = data.name1;
  if (data.name2 !== "") name = `${name} ${data.name2}`;

  // state
  const [state, setState] = useState({
    stock: parseInt(data?.stock ?? 0),
    price: parseInt(data?.price ?? 0),
    is_primary: parseInt(data?.is_primary ?? 0) === 1,
    is_active: parseInt(data?.is_active ?? 0) === 1,
  });

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeNumber = (value, name, values) => changeState({ [name]: value ?? 0 });

  const handleActive = (event) => changeState({ is_active: event.target.checked });
  const handlePrimary = async (is_primary = !state.is_primary) => {
    const post_data = { id: data.id, ...state };
    post_data.is_primary = is_primary ? 1 : 0;
    if (post_data.is_primary === 1) post_data.is_active = 1;
    props.handleChangeVarianPrimary(post_data);
    await updateVarian(product_id, post_data);
  };

  const updateStatus = async () => {
    const post_data = { id: data.id, ...state };
    post_data.is_active = state.is_active ? 1 : 0;
    if (post_data.price !== "" && post_data.stock !== "") {
      const res = await updateVarian(product_id, post_data);
      if (res) handleChangeVarian(res);
    }
  };

  //useEffect
  useEffect(() => {
    changeState({ is_primary: data.is_primary === 1, is_active: data.is_active === 1 });
  }, [data.is_primary, data.is_active]);

  useDebounce(() => { updateStatus() }, 500, [state.is_active, state.price, state.stock]);

  return (
    <div className={style.dtl_container}>
      <div className={style.dtl_container_part1}>
        <div className={style.name_container}>
          <label>{name}</label>
          <label>{`SKU : ${data.sku}`}</label>
        </div>
        <div className={style.primary}>
          {state.is_primary && <label>{"Utama"}</label>}
        </div>
      </div>
      <div className={style.dtl_container_part2}>
        <div className={style.input_container}>
          <label>Harga</label>
          <div className={style.input_price}>
            <span>Rp.</span>
            <CCurrencyInput name="price" value={state.price}
              onValueChange={changeNumber}
              onFocus={e => e.target.select()}
            />
          </div>
        </div>
        <div className={style.input_container}>
          <label>Stok</label>
          <CCurrencyInput name="stock" value={state.stock}
            onValueChange={changeNumber}
            onFocus={e => e.target.select()}
          />
        </div>
        <div className={style.action_container}>
          <Switcher
            id={data.id}
            toggle={state.is_active}
            onChangeToggle={handleActive}
            disabled={state.is_primary}
          />
          {!state.is_primary && (
            <i
              className={`bi bi-three-dots 2x`}
              onClick={() => handlePrimary()}
              data-bs-toggle="tooltip"
              title="Jadikan Utama"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default SellerProductDetailCardView;
