import { forwardRef } from "react";
import { Dropdown, NavDropdown } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import ReactQuill from "react-quill";
import styled from "styled-components";

export const Button = styled.button`
  width: 100%;
  color: #ffffff !important;
  border: 2px solid ${(props) => { return props.bg_color || "var(--main_color)" }};
  ${(props) => {
    const { active, disabled, bg_color } = props;
    if (active && !disabled) return { backgroundColor: bg_color || "var(--main_color)" };
    if (disabled)
      return {
        borderColor: "#696969 !important",
        backgroundColor: "#696969 !important",
      };
    return {
      color: "var(--main_color) !important",
      backgroundColor: "#ffffff",
    };
  }}
  border-radius: 0.5rem !important;
  &:hover {
    box-shadow: 0.1rem 0.1rem 0.1rem grey !important;
  }

  &:focus-visible {
    outline: none !important;
  }
`;

export const Tab = styled.button`
  font-weight: 700;
  color: var(--main_color);
  background-color: transparent;
  border: none;
  width: 100%;

  ${({ active, disabled }) => {
    if (active) return { borderBottom: "2px solid var(--main_color)" };
    if (disabled)
      return { cursor: "not-allowed !important", color: "#696969 !important" };
  }}

  &:focus-visible {
    outline: none !important;
  }

  @media screen and (max-width: 400px) {
    color: var(--main_color);
    border: 1px solid var(--main_color);
    margin-bottom: 0.2rem;
    border-radius: 0.5rem;

    ${({ active, disabled }) => {
    if (active)
      return { color: "white", backgroundColor: "var(--main_color)" };
    if (disabled)
      return {
        cursor: "not-allowed !important",
        color: "#696969 !important",
      };
  }}
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.2rem 0.5rem;
  border: 1px solid lightgray !important;
  border-radius: 0.5rem;
  box-sizing: border-box;
  box-shadow: none !important;
  color: black;

  &:focus-visible {
    outline: none !important;
  }

  // &:disabled {
  //   background-color: gray;
  // }
`;

export const CCurrencyInput = styled(CurrencyInput)`
  width: 100%;
  padding: 0.2rem 0.5rem;
  border: 1px solid lightgray !important;
  border-radius: 0.5rem;
  box-sizing: border-box;
  box-shadow: none !important;
  color: black;

  &:focus-visible {
    outline: none !important;
  }
`

export const InputLine = styled(Input)`
  border: none !important;
  border-bottom: 1px solid lightgray !important;
  border-radius: 0 !important;
`;

export const CNavDropdown = styled(NavDropdown)`
  width: 100%;
  a {
    padding: 0;
    width: 100%;
    color: white !important;

    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  a:focus-visible {
    outline: none;
  }

  .dropdown-menu {
    border-radius: 0.5rem;
    padding: 0.5rem;
    width: 100%;
    box-shadow: 0.5px 2px 5px lightgrey !important;
  }

  .dropdown-toggle::after {
    content: ${(props) => props.content || ""};
    vertical-align: 0.1em;
  }
`;

export const CSellerNavDropdown = styled(CNavDropdown)`
  .dropdown-menu {
    min-width: fit-content;
  }
`;

export const CDropdown = styled(Dropdown)`
  .btn {
    color: ${(props) => props.color ?? "var(--main_color)"} !important;
    padding: 0 0.5rem;
    background-color: ${(props) => props.bg_color ?? "var(--main_color)"} !important;
    border: 1px solid
      ${(props) => props.border_color ?? (props.bg_color ? "lightgrey" : "var(--main_color)")} !important;
  }

  * {
    box-shadow: none !important;
    outline: none !important;
    font-weight: 600;
  }

  a {
    white-space: normal;
  }

  a:hover {
    color: white;
    border-radius: 0.3rem;
    background-color: var(--main_color);
  }

  .dropdown-menu {
    padding: 0.4rem;
    max-height: 12rem;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0.5rem;
    box-shadow: 0px 2px 0px grey !important;
  }

  .dropdown-menu::-webkit-scrollbar {
    width: 0.3rem;
  }

  .dropdown-menu::-webkit-scrollbar-track {
    border: 1px solid #ffffff;
    border-radius: 0 0.5rem 0.5rem 0;
    background-color: #ffffff;
  }

  .dropdown-menu::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: ${(props) => props.scroll_color ?? "var(--main_color)"};
  }
`;

export const CDropdownLine = styled(CDropdown)`
  .btn {
    color: black !important;
    padding: 0 0.5rem !important;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid black !important;
    border-radius: unset;
    min-width: 12rem;
    margin-bottom: 0.5rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-toggle {
    width: 100%;
  }

  .dropdown-menu {
    width: 100%;
  }

  width: 100%;
`;

export const CDropdownLine2 = styled(CDropdown)`
  .btn {
    color: black !important;
    padding: 0 0.5rem !important;
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid lightgrey !important;
    border-radius: unset;
    min-width: 12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.65rem;
  }

  .btn span {
    font-weight: normal !important;
  }

  .dropdown-toggle {
    width: 100%;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    width: 100%;
  }

  width: fit-content;
`;

export const InputDate = forwardRef(({ value, onClick }, ref) => (
  <input
    style={{ cursor: "pointer" }}
    value={value}
    onClick={onClick}
    ref={ref}
    readOnly
  />
));

export const CEditor = styled(ReactQuill)`
  .ql-toolbar {
    border-radius: 0.5rem 0.5rem 0 0;
    border-color: ${props => props.border_color ?? ''} !important;
  }
  .ql-formats{
    border-right: 1px solid lightgrey;
    margin-right: 0 !important;
    padding: 0 5px

    &:last-child{
      border: none;
    }
  }

  .ql-container{
    border-radius: 0 0 0.5rem 0.5rem;
    min-height: ${props => props.minh ?? '150px'};
    max-height: ${props => props.maxh ?? '150px'};
    overflow: auto;
    border-color: ${props => props.border_color ?? ''} !important;
  }

  .ql-editing, .ql-tooltip:not(.ql-hidden){
    left: min(1%) !important;
  }

  .ql-editor{
    min-heigth : 150px;
  }

  .ql-tooltip:not(.ql-hidden){
    position : sticky;
    left: min(1%) !important;
    top : unset !important;
    bottom : 1rem;

    display : flex;
    align-items : center;

    input, .ql-preview{
      width: 100%;
    }
  }
`