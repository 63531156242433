import API, { APIAuth, APIImg } from "../utils/API"

// function
export const createUpdateStore = async (payload, process) => {
	let { logo, banner1, banner2, banner3, banner4 } = payload
	let banner = {}
	let result = undefined
	logo = await uploadImg(logo)
	banner1 = await uploadImg(banner1)
	banner2 = await uploadImg(banner2)
	banner3 = await uploadImg(banner3)
	banner4 = await uploadImg(banner4)
	if (banner1) banner["1"] = banner1
	if (banner2) banner["2"] = banner2
	if (banner3) banner["3"] = banner3
	if (banner4) banner["4"] = banner4

	const post_data = new FormData()
	post_data.append(`name`, payload.store_name)
	post_data.append(`color_id`, payload.color.id)
	post_data.append(`title`, payload.desc_title)
	post_data.append(`description`, payload.desc)
	post_data.append(`logo`, logo ?? "")
	post_data.append(`banner`, JSON.stringify(banner))
	post_data.append(`slogan`, payload.slogan)

	if (process === "store") {
		post_data.append(`subdomain`, payload.subdomain)
		post_data.append(`community_id`, payload.community_code)
		result = await createStore(post_data)
	}

	if (process === "update") result = await updateStore(post_data)

	return result
}

// callAPI
export const getColorList = async () => {
	return API.get(`param/color`)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return []
		})
}

export const getEcoDistrictParam = async (subdomain) => {
	return API.get(`param/edesa_district/${subdomain}`)
		.then((res) => res.data)
		.catch((err) => {
			// console.log(err);
			return []
		})
}

export const checkSubdomain = async (subdomain) => {
	return API.get(`check/subdomain?subdomain=${subdomain}`)
		.then((res) => true)
		.catch((err) => false)
}

export const checkCommunityCode = async (code) => {
	return API.get(`check/community?id=${code}`)
		.then((res) => true)
		.catch((err) => false)
}

export const uploadImg = async (image) => {
	const data = new FormData()
	data.append("image", image)
	if (image && typeof image === "object") {
		return APIImg.post(`image/upload`, data)
			.then((res) => res.data.name)
			.catch((err) => null)
	}
	return image
}

const createStore = async (data) => {
	return APIAuth.post(`seller`, data)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.log(err)
			return undefined
		})
}

const updateStore = async (data) => {
	return APIAuth.post(`seller/update`, data)
		.then((res) => {
			return res.data
		})
		.catch((err) => {
			console.log(err)
			return undefined
		})
}
