import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import style from "../../css/mainCSS/header.module.css";
import { toggleLogin } from "../../redux/globalAction";
import { BASE_URL_IMG_LOGO, DOMAIN, MERCHANT_1TOKO_LINK } from "../../utils/Constants";
import ShareCardView from "../CustomComponent/ShareCardView";
import { Input } from "../CustomComponent/StyledComponent";
import SellerMenu from "./SellerMenu";
import UserMenu from "./UserMenu";
import { isDomain } from "../../controller/authController";

function Header(props) {
  const [search, setSearch] = useState("");
  const [toggle, setToggle] = useState(false);
  const [toggle_share, setToggleShare] = useState(false);
  const storeData = useSelector((s) => s.global.storeData);
  const userData = useSelector((s) => s.global.userData);
  const toggleL = useSelector((s) => s.global.loginModal);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split("/")[1];
  const create_store_flag = storeData === undefined || (storeData?.is_ecosystems === 1 ?? false);
  var home_link = `https://www.${MERCHANT_1TOKO_LINK}`;
  var share_link = ``;

  if (storeData) {
    share_link = `${isDomain ? `www.${storeData.domain}` : `www.${storeData.subdomain}.${DOMAIN}`}/shop/${storeData.id}`;
    if (storeData.member_of_community) { home_link = `https://www.${storeData.member_of_community.subdomain}.${DOMAIN}`; }
    if (storeData.community_id) {
      share_link = `www.${storeData.subdomain}.${DOMAIN}/community/${storeData.id}`;
      // home_link =`https://${storeData.subdomain}.${DOMAIN}`
    }
  }

  // exception
  const exception_page = ["print", "midtrans", "reset_password", "ecosystem_login"];
  const exception_flag = exception_page.includes(path);

  // exception style
  const exception_page1 = ["login", "register"];
  const exception_flag1 = exception_page1.includes(path);
  const community_flag = storeData?.is_community;
  const exeption_style = exception_flag1 ? style.exception : "";
  const logo_style = `${style.store_logo}`;
  const logo = useSelector((s) => s.global.storeLogo) || null;

  // function
  const iconOnclick = (link) => navigate(link);
  const goToChat = () => { navigate("/user_profile/chat", { state: { start_chat: false } }); };
  const handleSearch = (event) => {
    event.preventDefault();
    const search_type = "product";
    if (search !== "") navigate(`/search?st=${search_type}&q=${search}`);
  };

  // useEffect
  useEffect(() => {
    if (toggle) {
      window.addEventListener("resize", () => {
        if (window.innerWidth > 850) setToggle(false);
      });
    }
  }, [window.innerWidth]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const _search = params.get("q");
    setSearch(_search ?? "");
    setToggle(false);
  }, [location]);

  // view
  if (exception_flag) return <></>;
  const backgroundColor = (exception_flag1 ? "transparent" : storeData?.color_code) || "transparent"
  return (
    <div className={`${style.container} ${exeption_style}`} style={{ backgroundColor }} >
      <OutsideClickHandler onOutsideClick={() => setToggle(false)}>
        <div className={style.inner_container}>
          {/* part logo & nama */}
          <div className={style.header_prt1}>
            <Link className={style.store_container} to="/" onClick={() => setToggle(false)}>
              {!community_flag ? (
                <>
                  <Image fluid src={logo} className={logo_style} />
                  <div className={style.store_name}>
                    {storeData?.name ?? ""}
                  </div>
                </>
              ) : (
                <div className={style.community_title} style={{ display: storeData ? "flex" : "none" }}>
                  Komunitas
                </div>
              )}
            </Link>
            <div className={style.icon_container} onClick={() => setToggle(!toggle)}>
              <i className="bi bi-list" />
            </div>
          </div>
          <div className={`${style.header_prt2} ${toggle ? style.slidedown : style.slideup}`}>
            {/* search */}
            <form className={`${style.search_container}`} onSubmit={(e) => handleSearch(e)}>
              <div className="input-group">
                <Input
                  type="text"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={`Cari Toko atau Produk`}
                  className={`form-control`}
                />
                <div className="input-group-text" onClick={(e) => handleSearch(e)}>
                  <i className={`bi bi-search`} style={{ color: "grey" }} />
                </div>
              </div>
            </form>
            {/* nav menu */}
            <ul className={style.nav_menu}>
              <div className={style.icon_menu_container}>
                {/* share */}
                <ShareCardView
                  toggle={toggle_share}
                  link={share_link}
                  position="left"
                  button={
                    <OutsideClickHandler onOutsideClick={() => setToggleShare(false)}>
                      <div className={style.icon_menu} onClick={() => setToggleShare(!toggle_share)}>
                        <i className="bi bi-share-fill" />
                      </div>
                    </OutsideClickHandler>
                  }
                />
                {!isDomain && (
                  <div className={style.icon_menu} onClick={() => (window.location.href = home_link)}>
                    <i className="bi bi-house-fill" />
                  </div>
                )}
                {userData && (
                  <>
                    <div className={style.icon_menu} onClick={() => iconOnclick("/cart")}>
                      <i className="bi bi-cart3" />
                    </div>
                    <div className={style.icon_menu} onClick={() => { }}>
                      <i className="bi bi-bell-fill" />
                    </div>
                    <div className={style.icon_menu} onClick={() => goToChat()}>
                      <i className="bi bi-chat" />
                    </div>
                  </>
                )}
              </div>
              {userData === undefined && (
                <>
                  <li className={style.before_login_container}>
                    <a onClick={() => dispatch(toggleLogin(!toggleL))}>Masuk</a>
                    <Link to="/register">Daftar</Link>
                  </li>
                </>
              )}
              <SellerMenu seller={userData?.seller} sub_seller={userData?.user_level_id === 8} />
              {create_store_flag && userData?.seller === null && (
                <li><a href="/create_store">Buka Toko</a></li>
              )}
              <UserMenu user={userData} />
            </ul>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

export default Header;
