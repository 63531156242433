import ChatMain from "../components/Chat/ChatMain";
import LocationMain from "../components/Location/LocationMain";
import BankAcountMain from "../components/ProfileSeller/BankAccount/BankAcountMain";
import PayoutTransactionMain from "../components/ProfileSeller/PayoutTransaction/PayoutTransactionMain";
import ProductEtalaseMain from "../components/ProfileSeller/ProductEtalase/ProductEtalaseMain";
import PromoMain from "../components/ProfileSeller/Promo/PromoMain";
import PayoutTransReportMain from "../components/ProfileSeller/Report/Payout/PayoutTransReportMain";
import SalesReportMain from "../components/ProfileSeller/Report/Sales/SalesReportMain";
import SellerProfileMain from "../components/ProfileSeller/SellerProfile/SellerProfileMain";
import SellerTransactionMain from "../components/ProfileSeller/SellerTransaction/SellerTransactionMain";
import StorePerformanceMain from "../components/ProfileSeller/StorePerformance/StorePerformanceMain";
import StoreStatusMain from "../components/ProfileSeller/StoreStatus/StoreStatusMain";
import UserAccessMain from "../components/ProfileSeller/UserAccess/UserAccessMain";
import ProductList from "../pages/ProductList";
import ProductSellerForm from "../pages/ProductSellerForm";

// seller menu => SMenu = Seller Menu
export const PROFILE_SMENU = "";
export const LOCATION_SMENU = "location";
export const BANK_SMENU = "bank";
export const STATUS_SMENU = "status";
export const USER_ACC_SMENU = "user_access";
export const PERFORMANCE_SMENU = "performance";
export const TRANS_SMENU = "transaction";
export const DISCUSSION_SMENU = "discussion";
export const CHAT_SMENU = "chat";
export const SUPPLIER_SMENU = "supplier";
export const COURIER_SMENU = "courier";
export const PROMO_SMENU = "promo";
export const ADD_PRODUCT_SMENU = "add_product";
export const EDIT_PRODUCT_SMENU = "edit_product";
export const LIST_PRODUCT_SMENU = "list_product";
export const LIST_ETALASE_SMENU = "list_etalase";
export const WITHDRAW_SMENU = "withdraw";
export const WITHDRAW_REPORT_SMENU = "withdraw_report";
export const TRANS_REPORT_SMENU = "transaction_report";

// seller menu title => ..._T => Title
export const PROFILE_SMENU_T = "PROFIL TOKO";
export const LOCATION_SMENU_T = "ALAMAT TOKO";
export const BANK_SMENU_T = "AKUN BANK TOKO";
export const STATUS_SMENU_T = "STATUS TOKO";
export const USER_ACC_SMENU_T = "AKSES USER";
export const PERFORMANCE_SMENU_T = "PERFORMA TOKO";
export const TRANS_SMENU_T = "PENJUALAN";
export const DISCUSSION_SMENU_T = "DISKUSI";
export const CHAT_SMENU_T = "CHAT";
export const SUPPLIER_SMENU_T = "DAFTAR SUPPLIER";
export const COURIER_SMENU_T = "KURIR INTERNAL";
export const PROMO_SMENU_T = "PROMO";
export const ADD_PRODUCT_SMENU_T = "TAMBAH PRODUK";
export const EDIT_PRODUCT_SMENU_T = "UBAH PRODUK";
export const LIST_PRODUCT_SMENU_T = "DAFTAR PRODUK";
export const LIST_ETALASE_SMENU_T = "DAFTAR ETALASE";
export const WITHDRAW_SMENU_T = "PENARIKAN DANA";
export const WITHDRAW_REPORT_SMENU_T = "LAPORAN PENARIKAN DANA";
export const TRANS_REPORT_SMENU_T = "LAPORAN PENJUALAN";

export const SMENU_ROUTES = [
  PROFILE_SMENU,
  LOCATION_SMENU,
  BANK_SMENU,
  STATUS_SMENU,
  PERFORMANCE_SMENU,
  TRANS_SMENU,
  DISCUSSION_SMENU,
  CHAT_SMENU,
  PROMO_SMENU,
  ADD_PRODUCT_SMENU,
  EDIT_PRODUCT_SMENU,
  LIST_PRODUCT_SMENU,
  LIST_ETALASE_SMENU,
  WITHDRAW_SMENU,
  WITHDRAW_REPORT_SMENU,
  TRANS_REPORT_SMENU,
  USER_ACC_SMENU
];

export const SMENU_SUBSELLER_ROUTES = [
  PERFORMANCE_SMENU,
  TRANS_SMENU,
  DISCUSSION_SMENU,
  CHAT_SMENU,
  ADD_PRODUCT_SMENU,
  EDIT_PRODUCT_SMENU,
  LIST_PRODUCT_SMENU,
  LIST_ETALASE_SMENU,
  USER_ACC_SMENU,
];

export const CG_SMENU_ROUTES = [
  PROFILE_SMENU,
  BANK_SMENU,
  STATUS_SMENU,
  PERFORMANCE_SMENU,
  TRANS_SMENU,
  DISCUSSION_SMENU,
  CHAT_SMENU,
  PROMO_SMENU,
  LIST_PRODUCT_SMENU,
  LIST_ETALASE_SMENU,
  WITHDRAW_SMENU,
  WITHDRAW_REPORT_SMENU,
  TRANS_REPORT_SMENU,
];

// STORE ACC MENU
export const SMENU_STORE_ACCOUNT = [
  { link: PROFILE_SMENU, title: PROFILE_SMENU_T },
  { link: LOCATION_SMENU, title: LOCATION_SMENU_T },
  { link: BANK_SMENU, title: BANK_SMENU_T },
  { link: STATUS_SMENU, title: STATUS_SMENU_T },
];

export const SMENU_PRIVATE_STORE_ACCOUNT = [
  { link: PROFILE_SMENU, title: PROFILE_SMENU_T },
  { link: LOCATION_SMENU, title: LOCATION_SMENU_T },
  { link: BANK_SMENU, title: BANK_SMENU_T },
  { link: STATUS_SMENU, title: STATUS_SMENU_T },
  { link: USER_ACC_SMENU, title: USER_ACC_SMENU_T },
];
// //////////////////////////////

// STORE MENU
export const SMENU_STORE = [
  { link: PERFORMANCE_SMENU, title: PERFORMANCE_SMENU_T },
  { link: TRANS_SMENU, title: TRANS_SMENU_T },
  // { link: DISCUSSION_SMENU, title: DISCUSSION_SMENU_T },
  { link: PROMO_SMENU, title: PROMO_SMENU_T },
  { link: CHAT_SMENU, title: CHAT_SMENU_T },
];

export const SMENU_SUB_STORE = [
  { link: PERFORMANCE_SMENU, title: PERFORMANCE_SMENU_T },
  { link: TRANS_SMENU, title: TRANS_SMENU_T },
  // { link: DISCUSSION_SMENU, title: DISCUSSION_SMENU_T },
  { link: CHAT_SMENU, title: CHAT_SMENU_T },
];

export const SMENU_CS_STORE = [
  { link: TRANS_SMENU, title: TRANS_SMENU_T },
  // { link: DISCUSSION_SMENU, title: DISCUSSION_SMENU_T },
  { link: CHAT_SMENU, title: CHAT_SMENU_T },
];
// ///////////////////////////////

// PRODUCT MENU
export const SMENU_PRODUCT = [
  { link: ADD_PRODUCT_SMENU, title: ADD_PRODUCT_SMENU_T },
  { link: LIST_PRODUCT_SMENU, title: LIST_PRODUCT_SMENU_T },
  { link: LIST_ETALASE_SMENU, title: LIST_ETALASE_SMENU_T },
];

export const CG_SMENU_PRODUCT = [
  { link: LIST_PRODUCT_SMENU, title: LIST_PRODUCT_SMENU_T },
  { link: LIST_ETALASE_SMENU, title: LIST_ETALASE_SMENU_T },
];
// ///////////////////////////////

// FINANCE MENU
export const SMENU_FINANCE = [
  { link: WITHDRAW_SMENU, title: WITHDRAW_SMENU_T },
  { link: WITHDRAW_REPORT_SMENU, title: WITHDRAW_REPORT_SMENU_T },
  { link: TRANS_REPORT_SMENU, title: TRANS_REPORT_SMENU_T },
];
// ////////////////////////////

const sellerPage = {
  [PROFILE_SMENU]: { title: PROFILE_SMENU_T, component: <SellerProfileMain /> },
  [LOCATION_SMENU]: { title: LOCATION_SMENU_T, component: <LocationMain seller /> },
  [BANK_SMENU]: { title: BANK_SMENU_T, component: <BankAcountMain /> },
  [STATUS_SMENU]: { title: STATUS_SMENU_T, component: <StoreStatusMain /> },
  [USER_ACC_SMENU]: { title: USER_ACC_SMENU_T, component: <UserAccessMain /> },
  [PERFORMANCE_SMENU]: { title: PERFORMANCE_SMENU_T, component: <StorePerformanceMain /> },
  [TRANS_SMENU]: { title: TRANS_SMENU_T, component: <SellerTransactionMain /> },
  [CHAT_SMENU]: { title: CHAT_SMENU_T, component: <ChatMain type="seller" /> },
  [PROMO_SMENU]: { title: PROMO_SMENU_T, component: <PromoMain /> },
  [ADD_PRODUCT_SMENU]: { title: ADD_PRODUCT_SMENU_T, component: <ProductSellerForm /> },
  [EDIT_PRODUCT_SMENU]: { title: EDIT_PRODUCT_SMENU_T, component: <ProductSellerForm /> },
  [LIST_PRODUCT_SMENU]: { title: LIST_PRODUCT_SMENU_T, component: <ProductList /> },
  [LIST_ETALASE_SMENU]: { title: LIST_ETALASE_SMENU_T, component: <ProductEtalaseMain /> },
  [WITHDRAW_SMENU]: { title: WITHDRAW_SMENU_T, component: <PayoutTransactionMain /> },
  [WITHDRAW_REPORT_SMENU]: { title: WITHDRAW_REPORT_SMENU_T, component: <PayoutTransReportMain /> },
  [TRANS_REPORT_SMENU]: { title: TRANS_REPORT_SMENU_T, component: <SalesReportMain /> },
  // [DISCUSSION_SMENU]:{ title: DISCUSSION_SMENU_T, component: <>diskusi</> }
}

// transaction => TSM = Transaction Menu Seller
export const TMS_NOT_PAID = "pending";
export const TMS_CONFRIM = "confrim";
export const TMS_PICKUP = "pickup";
export const TMS_SENT = "sent";
export const TMS_FINISHED = "finished";
export const TMS_RCANCEL = "request_cancel";
export const TMS_CANCELED = "canceled";
export const TMS_COMPLAINT = "complaint_order";

export const TMS_MENU = [
  { code: TMS_NOT_PAID, name: "belum dibayar" },
  { code: TMS_CONFRIM, name: "konfirmasi pesanan" },
  { code: TMS_PICKUP, name: "siap diambil" },
  { code: TMS_SENT, name: "pesanan dikirim" },
  { code: TMS_FINISHED, name: "pesanan selesai" },
  { code: TMS_RCANCEL, name: "pesanan dibatalkan" },
  { code: TMS_CANCELED, name: "pesanan ditolak" },
  // { code: TMS_COMPLAINT, name: "pesanan dikomplain" },
];

// PROMO
export const PRM_UNIT = [
  { label: "Rp", value: "rupiah" },
  { label: "%", value: "percent" },
];

export const PRM_PROMO_FILLTER = [
  { label: "All", value: 0 },
  { label: "Aktif", value: 1 },
  { label: "Tidak Aktif", value: 2 },
];

export const REFERAL = "referral";
export const PROMOTION = "promotion";

// SELF COURIER RADIUS
export const COURIER_RADIUS = [
  { label: "2 KM", value: 2 },
  { label: "5 KM", value: 5 },
  { label: "10 KM", value: 10 },
  { label: "15 KM", value: 15 },
  { label: "20 KM", value: 20 },
  { label: "25 KM", value: 25 },
  { label: "30 KM", value: 30 },
];

// SELLER PRODUCT
export const DEFAULT_VARIAN_TYPE = ["Warna", "Ukuran"];
export const COLOR_VARIAN_TYPE = ["Putih", "Hitam"];
export const SIZE_VARIAN_TYPE = ["XL", "L"];

// function
export function getSellerPage(pageName) {
  return sellerPage[pageName] ?? { title: "", component: <></> }
}

export function getSellerMenu(cg_flag = false, sub_seller = false) {
  if (sub_seller) return SMENU_SUBSELLER_ROUTES;
  if (cg_flag) return CG_SMENU_ROUTES;
  return SMENU_ROUTES;
}

