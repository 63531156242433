import Swal from "sweetalert2"
import API, { APIAuth, APIAuthFile } from "../utils/API"
import { addUpdateEtalase } from "./productEtalaseController"
import { uploadImg } from "./sellerProfileController"

export const initVarianType = (def_type, type = []) => {
	var type_list = [...def_type]
	type.map((t) => {
		if (!def_type.includes(t)) type_list.push(t)
	})
	return type_list
}

export const generateVarian = (type1, type2, type1_list = [], type2_list = []) => {
	const flag = type2_list.length !== 0
	let temp_list = []
	type1_list.forEach((t1, idx) => {
		let temp = {
			group1: type1,
			group2: type2,
			name1: t1,
			name2: "",
			price: 0,
			stock: 0,
			sku: "",
			image: null,
			is_primary: idx === 0 ? 1 : 0,
			is_active: 1,
		}
		flag &&
			type2_list.forEach((t2, idx2) => {
				const primary = idx === 0 && idx2 === 0 ? 1 : 0
				temp = { ...temp, name2: t2, is_primary: primary }
				temp_list.push(temp)
			})
		!flag && temp_list.push(temp)
	})
	return temp_list
}

export const deleteProduct = (product_id) => {
	Swal.fire({
		icon: "question",
		text: `Apakah Anda yakin untuk menghapus barang ini ?`,
		allowOutsideClick: false,
		showCancelButton: true,
		confirmButtonText: "Hapus",
		confirmButtonColor: "var(--main_color)",
	}).then(async (res) => {
		if (!res.value) return
		Swal.fire({
			didOpen: () => Swal.showLoading(),
			allowOutsideClick: false,
		})
		const del_res = await updateProductActive(product_id, 2)
		Swal.close()
		Swal.fire({
			icon: del_res ? "success" : "error",
			text: del_res ? `Berhasil dihapus !` : del_res.errors[0],
			confirmButtonColor: "var(--main_color)",
		}).then((res) => {
			if (del_res) window.location = "/seller_dashboard/list_product"
		})
	})
}

const processProductImg = async (data) => {
	var { photo1, photo2, photo3, photo4, photo5 } = data
	const res = {}
	if (photo1) res["1"] = await uploadImg(photo1)
	if (photo2) res["2"] = await uploadImg(photo2)
	if (photo3) res["3"] = await uploadImg(photo3)
	if (photo4) res["4"] = await uploadImg(photo4)
	if (photo5) res["5"] = await uploadImg(photo5)
	return res
}

const processProductVarian = async (varian_list) => {
	const temp = await Promise.all(
		varian_list.map(async (varian) => {
			const img = await uploadImg(varian.image)
			varian.image = img
			return varian
		})
	)
	return temp
}

export const addUpdateProduct = async (data) => {
	let res = undefined
	var { length, width, height, discount, description, stock } = data
	const { video_link1, video_link2, video_link3 } = data
	length = length === "" ? 0 : length
	width = width === "" ? 0 : width
	height = height === "" ? 0 : height
	discount = discount === "" ? 0 : discount
	description = ["", null, undefined].includes(description) ? "-" : description
	stock = stock === "" ? 0 : stock

	var etalase = data.etalase
	var varian = data.varian
	const varian_flag = varian.length > 0
	const img_produk = await processProductImg(data)
	if ([null, undefined].includes(etalase?.id) && etalase.trim() !== "") {
		etalase = await addUpdateEtalase({ name: etalase })
	}
	if (varian_flag) varian = await processProductVarian(varian)

	const videoFlag = video_link1 === "" && video_link2 === "" && video_link3 === ""
	const external_video = videoFlag ? [] : [video_link1, video_link2, video_link3]

	const post_data = new FormData()
	post_data.append(`image`, JSON.stringify(img_produk))
	post_data.append(`name`, data.name)
	post_data.append(`description`, description)
	if (data.category?.id) post_data.append(`category_id`, data.category?.id)
	post_data.append(`etalase_id`, etalase?.id ?? "")
	if (data?.is_private) post_data.append(`sub_etalase_id`, data?.sub_etalase?.id ?? null)
	post_data.append(`varian`, JSON.stringify(varian))
	post_data.append(`price`, !varian_flag ? data.price : 0)
	post_data.append(`stock`, !varian_flag ? stock : 0)
	post_data.append(`sku`, data.sku !== "" ? data.sku : "")
	post_data.append(`minimum_order`, data.min_order)
	post_data.append(`weight`, data.weight)
	post_data.append(`length`, length)
	post_data.append(`width`, width)
	post_data.append(`height`, height)
	post_data.append(`discount`, discount)
	post_data.append(`is_po`, data.pre_order)
	post_data.append(`po_expired_time`, data.pre_order_time)
	post_data.append(`is_custom_courier`, data.is_custom_courier)
	post_data.append(`custom_courier`, JSON.stringify(data.custom_courier))
	post_data.append(`self_pickup`, data.self_pickup)
	post_data.append(`self_courier`, data.self_courier)
	post_data.append(`self_courier_radius`, data.self_courier_radius)
	post_data.append(`self_courier_price`, data.self_courier_price)
	post_data.append(`is_shown_mitra`, data.is_shown_mitra)
	post_data.append(`external_video`, JSON.stringify(external_video))
	post_data.append(`keywords`, data.keywords)
	res = await (data.id ? updateProduct(post_data, data.id) : createProduct(post_data))
	return res
}

// call API
export const getCategoryList = async () => {
	return API.get(`param/category`)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return []
		})
}

export const getCourierList = async () => {
	return API.get(`param/courier`)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return []
		})
}

export const getSellerProductList = async (payload) => {
	const _pages = `?page=${payload?.page ?? 1}`
	const _limits = `&limit=${payload?.limit ?? 10}`
	const _search = `&q=${payload?.search ?? ""}`
	return APIAuth.get(`product${_pages}${_limits}${_search}`)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return undefined
		})
}

export const updateProductActive = async (product_id, status) => {
	const data = new FormData()
	data.append(`active`, status)
	return APIAuth.post(`product/active/${product_id}`, data)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return undefined
		})
}

export const updateProductPriceAndStok = async (product_id, price, stock) => {
	const data = new FormData()
	data.append(`price`, price)
	data.append(`stock`, parseInt(stock))
	return APIAuth.post(`product/update/${product_id}`, data)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return undefined
		})
}

export const updateVarian = async (product_id, varian) => {
	const data = new FormData()
	data.append(`varian`, JSON.stringify([varian]))
	return APIAuth.post(`product/updatevarian/${product_id}`, data)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return undefined
		})
}

const createProduct = (data) => {
	return APIAuth.post(`product`, data)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return undefined
		})
}

const updateProduct = (data, product_id) => {
	// console.log(data)
	return APIAuth.post(`product/update/${product_id}`, data)
		.then((res) => res.data)
		.catch((err) => {
			return undefined
		})
}

export const uploadProduct = (file) => {
	const data = new FormData()
	data.append("file", file)
	return APIAuthFile.post(`auth/importitem`, data)
		.then((res) => res.data)
		.catch((err) => null)
}
