import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import React, { useCallback, useState } from "react";
import DatePicker from "react-datepicker";
import id from "date-fns/locale/id";
import style from "../../../../css/sellerProfile/report/salesReport.module.css";
import pt_style from "../../../../css/sellerProfile/payoutTransaction.module.css";
import { InputDate, InputLine } from "../../../CustomComponent/StyledComponent";
import {
  getSalesReport,
  getSalesRptExcelData,
} from "../../../../controller/reportController";
import SalesReportCardView from "./SalesReportCardView";
import InvoiceCardLoading from "../../../Loading/InvoiceCardLoading";
import SalesReportExcel from "./SalesReportExcel";
import SalesReportLoading from "../../../Loading/SalesReportLoading";
import useDebounce from "../../../../utils/useDebounce";

const date = dayjs();
const from_date = date.subtract(30, "day").format("YYYY-MM-DD");
const to_date = date.format("YYYY-MM-DD");
const _FDate = "dd - MM - yyyy";

const initState = {
  list: [],
  excel_data: [],
  current_page: 1,
  search: "",
  hasMore: false,
  from_date: dayjs(from_date).toDate(),
  to_date: dayjs(to_date).toDate(),

  is_loading: false,
  is_loading_page: false,
};

function SalesReportMain(props) {
  const [state, setState] = useState(initState);
  const { current_page, total_per_page, from_date, to_date } = state;
  const { is_loading, is_loading_page, list } = state;

  // fucntion
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeSearch = (search) => changeState({ search });
  const changeDate = (date, type) => {
    const data = { [`${type}_date`]: date };
    if (type === "from") data.to_date = dayjs(date).add(30, "day").toDate();
    changeState(data);
  };

  // Ref
  const observer = useRef();
  const lastElRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && state.hasMore && !is_loading_page) {
          changeState({ is_loading: true });
          const _page = state.current_page + 1;
          const data = {};
          const tempList = await getSalesReport(
            dayjs(from_date).format("YYYY-MM-DD"),
            dayjs(to_date).format("YYYY-MM-DD"),
            state.search,
            _page
          );
          if (tempList) {
            data.list = [...state.list, ...tempList.data];
            data.current_page = tempList.current_page;
            data.hasMore = tempList.current_page < tempList.last_page;
          }
          changeState({ ...data, is_loading: false });
        }
      });
      if (node) observer.current.observe(node);
    },
    [state.hasMore, state.current_page]
  );

  // function
  const loadPage = async (page = 1, limit = 5, search = state.search) => {
    if (!is_loading_page) {
      changeState({ is_loading: true });
      const data = {};
      const tempList = await getSalesReport(
        dayjs(from_date).format("YYYY-MM-DD"),
        dayjs(to_date).format("YYYY-MM-DD"),
        search,
        page,
        limit
      );
      const excel_data = await getSalesRptExcelData(
        dayjs(from_date).format("YYYY-MM-DD"),
        dayjs(to_date).format("YYYY-MM-DD"),
        search
      );
      if (tempList) {
        data.excel_data = excel_data;
        data.list = tempList.data;
        data.current_page = tempList.current_page;
        data.hasMore = tempList.current_page < tempList.last_page;
      }
      changeState({ ...data, is_loading: false });
    }
  };

  const initPage = async () => {
    changeState({ is_loading_page: true });
    const data = {};
    const tempList = await getSalesReport(
      dayjs(from_date).format("YYYY-MM-DD"),
      dayjs(to_date).format("YYYY-MM-DD")
    );
    const excel_data = await getSalesRptExcelData(
      dayjs(from_date).format("YYYY-MM-DD"),
      dayjs(to_date).format("YYYY-MM-DD")
    );
    if (tempList) {
      data.excel_data = excel_data;
      data.list = tempList.data;
      data.current_page = tempList.current_page;
      data.hasMore = tempList.current_page < tempList.last_page;
    }
    changeState({ ...data, is_loading: false, is_loading_page: false });
  };

  // useEffect
  useEffect(() => { initPage() }, []);
  useDebounce(() => loadPage(current_page, total_per_page), 500, [
    state.search,
    from_date,
    to_date,
  ]);

  if (is_loading_page) return <SalesReportLoading />;
  return (
    <div className={pt_style.container}>
      <div
        className={`${pt_style.out_date_container} ${pt_style.right_position}`}
      >
        <span>Rentang Tanggal</span>
        <div className={pt_style.date_container}>
          <DatePicker
            locale={id}
            dateFormat={_FDate}
            selected={state.from_date}
            onChange={(date) => changeDate(date, "from")}
            popperClassName={pt_style.date_poper}
            customInput={<InputDate />}
          />
          <span>s/d</span>
          <DatePicker
            locale={id}
            dateFormat={_FDate}
            minDate={state.from_date}
            selected={state.to_date}
            onChange={(date) => changeDate(date, "to")}
            customInput={<InputDate />}
          />
        </div>
      </div>

      <div className={`${pt_style.btn_container} ${pt_style.right_position}`}>
        <SalesReportExcel dataset={state.excel_data} />
      </div>
      <div className={pt_style.filter_container}>
        <InputLine
          value={state.search}
          onChange={(e) => changeSearch(e.target.value)}
          placeholder="Cari Data"
        />
      </div>
      <div className={style.list_container}>
        {is_loading && <InvoiceCardLoading />}
        {!is_loading && list.length === 0 && <>kosong</>}
        {!is_loading &&
          list.map((data, idx) => {
            const key = `sales_rpt_${idx}`;
            if (idx + 1 === list.length) {
              return (
                <SalesReportCardView
                  key={key}
                  data={data}
                  lastElRef={lastElRef}
                />
              );
            }
            return <SalesReportCardView key={key} data={data} />;
          })}
      </div>
    </div>
  );
}

export default SalesReportMain;
