import React, { useCallback, useEffect, useState } from "react";
import style from "../../../css/sellerProfile/promo.module.css";
import { Button } from "../../CustomComponent/StyledComponent";

const initState = {
  text: "",
  old_text: "",
  text_length: 0,
  max_length: 1500,
  is_loading: false,
};

function PromotionSettingView(props) {
  const [state, setState] = useState(initState);

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeText = (text) => {
    const text_length = text.length;
    changeState({ text, text_length });
  };
  const loadData = useCallback(async () => {
    const text = "";
    changeState({ text, is_loading: false });
  }, []);

  const initPage = () => {
    changeState({ is_loading: true });
    loadData();
  };

  const handleCancel = () => {
    const text = state.old_text;
    const text_length = text.length;
    changeState({ text, text_length });
  };

  const handleSubmit = async () => {
    changeState({ is_loading: true });
    const res = "";
    changeState({ is_loading: false });
  };
  // useEffect
  useEffect(() => { initPage() }, []);

  // view
  return (
    <>
      <div className={style.promo_info_container}>
        <label>
          (*) Silahkan ketik kalimat promosi yang Anda butuhkan. Kalimat yang
          Anda simpan ini akan muncul saat melakukan share toko Anda ke beberapa
          sosial media yang tersedia.
        </label>
      </div>
      <div className={style.promotion_content}>
        <div className={style.psub_input}>
          <div id={style.subheader}>
            <label id={style.title}>Deskripsi Promo</label>
            <label id={style.count}>
              {`${state.text_length} / ${state.max_length}`}
            </label>
          </div>
          <textarea
            autoFocus={true}
            maxLength={state.max_length}
            value={state.text}
            onChange={(e) => changeText(e.target.value)}
          />
        </div>
        <div className={style.btn_container}>
          <Button onClick={() => handleCancel()}>Batal</Button>
          <Button active onClick={() => handleSubmit()}>
            Simpan
          </Button>
        </div>
      </div>
    </>
  );
}

export default PromotionSettingView;
