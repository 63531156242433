import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  deleteBankAccount,
  getBankAccountList,
  setAsPrimary,
} from "../../../controller/bankAccountController";
import BankAccountFormModalView from "./BankAccountFormModalView";
import style from "../../../css/sellerProfile/bank.module.css";
import { Button, Input } from "../../CustomComponent/StyledComponent";
import Swal from "sweetalert2";
import DeleteValidationModalView from "./DeleteValidationModalView";
import LocationCardLoading from "../../Loading/LocationCardLoading";

const initState = {
  bank_id: undefined,
  list: [],
  search_list: [],
  search: "",
  select_data: undefined,
  toggle: false,
  otp_toggle: false,
  is_loading: true,
};

function BankAcountMain(props) {
  const [state, setState] = useState(initState);
  const { search_list, is_loading } = state;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const updateList = useCallback(async () => {
    let list = await getBankAccountList();
    let search_list = fillterList(list);
    changeState({ list, search_list, is_loading: false });
  }, []);

  const initPage = () => {
    changeState({ is_loading: true });
    updateList();
  };

  const _popupHandler = (toggle, process = "ADD", data = undefined) => {
    const flag = process === "FINISH";
    changeState({ toggle, select_data: data, is_loading: flag });
    if (flag) updateList();
  };

  const alert = async (icon, text) => {
    return Swal.fire({
      icon,
      text,
      allowOutsideClick: false,
      cancelButtonText: "Batal",
      showCancelButton: true,
      confirmButtonText: "Ok",
      confirmButtonColor: "var(--main_color)",
      cancelButtonColor: "#d33",
    }).then((res) => res);
  };

  const handleSetPrimary = async (id) => {
    const res = await setAsPrimary(id);
    if (res) updateList();
  };

  const handleDelete = async (id) => {
    let conf = await alert("warning", "Hapus Rekening ini ?");
    if (conf.value) changeState({ otp_toggle: true, bank_id: id });
  };

  const handleDeleteOTP = async (process = "CANCEL") => {
    const flag = process === "FINISH";
    changeState({ otp_toggle: false, bank_id: undefined });
    if (flag) updateList();
  };

  const fillterList = (list) => {
    return list.filter((data) => {
      const search = state.search.toLowerCase();
      const bank = data.bank_name.toLowerCase();
      const no_rek = data.no_rek.toLowerCase();
      const name = data.name.toLowerCase();
      return (
        name.indexOf(search) > -1 ||
        no_rek.indexOf(search) > -1 ||
        bank.indexOf(search) > -1
      );
    });
  };

  // useEffect
  useEffect(() => { initPage() }, []);
  useEffect(() => {
    const search_list = fillterList(state.list);
    changeState({ search_list });
  }, [state.search]);

  // view
  return (
    <>
      {state.toggle && !is_loading && (
        <BankAccountFormModalView
          data={state.select_data}
          toggle={state.toggle}
          popupHandler={_popupHandler}
        />
      )}
      {state.otp_toggle && !is_loading && (
        <DeleteValidationModalView
          acc_id={state.bank_id}
          toggle={state.otp_toggle}
          popupHandler={handleDeleteOTP}
        />
      )}
      <div className={style.container}>
        <div className={style.sub_header}>
          <Input
            value={state.search}
            placeholder="Cari No. Rekening atau Nama Bank"
            onChange={(e) => changeState({ search: e.target.value })}
          />
          <Button active onClick={() => _popupHandler(true)}>
            + Tambah Rekening
          </Button>
        </div>
        <div className={style.list_outer_container}>
          <div className={style.list_container}>
            {is_loading && <LocationCardLoading count={3} />}
            {!is_loading && search_list.length === 0 && <>Tidak ada Rekening</>}
            {!is_loading &&
              search_list.length > 0 &&
              search_list.map((data, idx) => {
                const primary = data.is_primary === 1;
                return (
                  <div key={`bank${idx}`} className={style.card}>
                    <div className={style.bank_container}>
                      <div id={style.name}>{data.bank_name}</div>
                      {primary && <div id={style.primary}>Utama</div>}
                    </div>
                    <div className={style.text_container}>
                      <div id={style.left}>No. Rekening</div>
                      <div id={style.right}>{data.no_rek}</div>
                    </div>
                    <div className={style.text_container}>
                      <div id={style.left}>Atas Nama</div>
                      <div id={style.right}>{data.name}</div>
                    </div>
                    <div className={style.action_container}>
                      <div
                        className={style.action_i}
                        onClick={() => _popupHandler(true, "UPDATE", data)}
                      >
                        Ubah Rekening
                      </div>
                      <div
                        className={style.action_i}
                        onClick={() => handleDelete(data.id)}
                      >
                        Hapus
                      </div>
                      {!primary && (
                        <div
                          className={style.action_i}
                          onClick={() => handleSetPrimary(data.id)}
                        >
                          Jadikan Rekening Utama
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default BankAcountMain;
