import React, { useEffect, useState } from "react";
import { updateProfile } from "../../../controller/userProfileController";
import { regexName } from "../../../utils/Constants";
import CustomModal, { HeaderModal } from "../../CustomComponent/CustomModal";
import { Input, Button } from "../../CustomComponent/StyledComponent";
import style from "../../../css/userProfile/profile.module.css";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { globalStorage } from "../../../utils/Storage";
import Swal from "sweetalert2";
import dayjs from "dayjs";

import id from "date-fns/locale/id";
import { formatDate } from "../../../controller/globalController";
import { changeUser } from "../../../redux/globalAction";

function getTitle(type) {
  if (type === "name") return "Nama";
  if (type === "dob") return "Tanggal Lahir";
  if (type === "gender") return "Jenis Kelamin";
  return "";
}

function ChangeProfileModalView(props) {
  const { type, toggle, popupHandler } = props;
  const dispatch = useDispatch();
  const userData = useSelector((s) => s.global.userData);
  const [state, setState] = useState(userData[type]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const title = `${state === null ? "Tambah" : "Ubah"} ${getTitle(type)}`;

  // function
  const changeNameHandler = (data) => {
    if (regexName.test(data) || data === "") setState(data);
  };

  const changeDate = (date) => {
    const state = formatDate(dayjs(date).toDate(), "YYYY-MM-D");
    setState(state);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!["", null].includes(state)) {
      setLoading(true);
      Swal.showLoading();
      const res = await updateProfile(type, state);
      if (res) {
        const new_data = state;
        userData[type] = new_data;
        globalStorage.setItem("UD", userData);
        dispatch(changeUser(userData));
        Swal.fire({
          title: "Berhasil",
          icon: "success",
        }).then(() => {
          popupHandler(false, type, new_data);
          //   // window.location.reload();
        });
      } else {
        Swal.fire({
          title: "Gagal",
          icon: "error",
        }).then(() => {
          setLoading(false);
        });
      }
    } else {
      setError("Data tidak boleh kosong");
    }
  };

  // useEffect
  useEffect(() => { setError("") }, [state]);

  return (
    <CustomModal
      outsideClick
      toggle={toggle}
      modalHandler={() => popupHandler(false, "")}
      size="sm"
    >
      <HeaderModal title={title} modalHandler={() => popupHandler(false, "")} />
      <form className={style.modal_container}>
        {type === "name" && (
          <Input
            value={state}
            onChange={(e) => changeNameHandler(e.target.value)}
          />
        )}
        {type === "dob" && (
          <div className={style.dob_container}>
            <label>
              {`Tanggal Ulang Tahun : ${state !== null
                  ? formatDate(dayjs(state).toDate(), "DD MMMM YYYY")
                  : "-"
                }`}
            </label>
            <DatePicker
              locale={id}
              selected={state !== null ? dayjs(state).toDate() : ""}
              dateFormat={"dd-MM-yyyy"}
              onChange={(date) => changeDate(date, "start")}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              inline
            />
          </div>
        )}
        {type === "gender" && (
          <div className={style.gender_container}>
            <div className={`form-check ${style.gender_input}`}>
              <Input
                id="gender1"
                type="radio"
                className="form-check-input"
                onChange={() => setState("1")}
                value={"1"}
                checked={state === "1"}
              />
              <label className="form-check-label" htmlFor="gender1">
                Laki - laki
              </label>
            </div>
            <div className={`form-check ${style.gender_input}`}>
              <Input
                id="gender2"
                type="radio"
                className="form-check-input"
                onChange={() => setState("2")}
                value={"2"}
                checked={state === "2"}
              />
              <label className="form-check-label" htmlFor="gender2">
                Perempuan
              </label>
            </div>
          </div>
        )}
        {error !== "" && <label className="error_txt">{error}</label>}
        {!loading && (
          <Button
            active
            type="submit"
            disabled={loading}
            onClick={(e) => handleSubmit(e)}
          >
            Simpan
          </Button>
        )}
        {loading && <>Loading</>}
      </form>
    </CustomModal>
  );
}

export default ChangeProfileModalView;
