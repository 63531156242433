import React, { useCallback, useEffect, useState } from "react";
import DataTable, { defaultThemes } from "react-data-table-component";
import style from "../../../css/sellerProfile/promo.module.css";
import { getPromoList, getStatus } from "../../../controller/promoController";
import { formatCurrency } from "../../../controller/globalController";
import ReferralSettingFormView from "./ReferralSettingFormView";
import ReferralTransHistoryModalView from "./ReferralTransHistoryModalView";
import { CDropdown, Input } from "../../CustomComponent/StyledComponent";
import { PRM_PROMO_FILLTER } from "../../../utils/ConstantSeller";

const initState = {
  promo_list: [],
  search_list: [],
  selected_promo: undefined,

  //table
  search: "",
  fillter: undefined,
  status_filer: "",
  current_page: 1,
  total_per_page: 5,
  total_data: 1,

  // other
  is_edit: false,
  is_loading: false,
  history_modal: false,
};

function ReferralSettingView(props) {
  const [state, setState] = useState(initState);
  const { selected_promo, fillter } = state;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));

  const loadList = useCallback(async () => {
    const promo_list = await getPromoList();
    changeState({
      promo_list: promo_list,
      search_list: promo_list,
      total_data: promo_list.length,
      is_loading: false,
      is_edit: false,
      selected_promo: undefined,
      search: "",
    });
  }, []);

  const initPage = async () => {
    changeState({ is_loading: true });
    loadList();
  };

  const handleSearch = (event) => changeState({ search: event.target.value });
  const handleSelect = (fillter) => changeState({ fillter });
  const changePage = (current_page) => changeState({ current_page });
  const changePerRows = (total_per_page) => changeState({ total_per_page });
  const fillterList = (list) => {
    return list.filter((data) => {
      const search = state.search.toLowerCase();
      const name = data.name.toLowerCase();
      const promo_code = data.promo_code.toLowerCase();
      let flag = true;
      const status = getStatus(data.start_period, data.end_period);
      if (fillter?.value === 1) flag = status === "Aktif";
      if (fillter?.value === 2) flag = status !== "Aktif";
      return (
        (name.indexOf(search) > -1 || promo_code.indexOf(search) > -1) && flag
      );
    });
  };

  // table function
  const handleRTrnsModal = (data, toggle = !state.history_modal) => {
    changeState({
      selected_promo: data,
      is_edit: false,
      history_modal: toggle,
    });
  };
  const handleEdit = (data) => {
    changeState({ selected_promo: data, is_edit: true });
  };

  // useEffect
  useEffect(() => { initPage() }, []);
  useEffect(() => {
    const search_list = fillterList(state.promo_list);
    changeState({ search_list });
  }, [state.search, state.fillter, state.current_page, state.total_per_page]);

  // table props
  const columns = [
    {
      name: "Kode Referral",
      selector: (row) => (
        <div className={style.promo_code} onClick={() => handleRTrnsModal(row)}>
          {row.promo_code}
        </div>
      ),
    },
    {
      name: "Nama Pengguna",
      selector: (row) => row.name,
    },
    {
      name: "Promo Terpakai",
      selector: (row) => row.used_promo,
    },
    {
      name: "Total Komisi",
      selector: (row) => formatCurrency(row.total_commission),
    },
    {
      name: "Total Diskon",
      selector: (row) => formatCurrency(row.total_discount),
    },
    {
      name: "Status",
      selector: (row) => {
        const temp_stat = getStatus(row.start_period, row.end_period);
        const flag = temp_stat === "Aktif";
        const style_class = style[flag ? "active_stat" : "non_active_stat"];
        return <div className={style_class}>{temp_stat}</div>;
      },
    },
    {
      name: "Action",
      ignoreRowClick: true,
      cell: (row, index) => {
        return (
          <div key={row.mitra_id + index}>
            <i
              className="bi bi-pencil-square"
              onClick={() => handleEdit(row)}
            />
          </div>
        );
      },
    },
  ];
  const customStyles = {
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
      },
    },
  };

  return (
    <>
      {state.history_modal && state.selected_promo && (
        <ReferralTransHistoryModalView
          selected_promo={selected_promo}
          toggle={state.history_modal}
          popupHandler={handleRTrnsModal}
        />
      )}
      <ReferralSettingFormView
        isEdit={state.is_edit}
        selected_promo={selected_promo}
        loadList={loadList}
      />
      <div className={style.filter_container}>
        <Input
          value={state.search}
          onChange={(e) => handleSearch(e)}
          placeholder="Cari Nama Pengguna / Kode Referral"
        />
        <CDropdown
          className={style.unit_dropdown}
          bg_color="white"
          color="black"
        >
          <CDropdown.Toggle id="dropdown-autoclose-true">
            {fillter ? fillter.label : "Fillter"}
            <CDropdown.Menu>
              {PRM_PROMO_FILLTER.map((data, idx) => {
                return (
                  <CDropdown.Item
                    key={`fillter_${idx}`}
                    onClick={() => handleSelect(data)}
                  >
                    {data.label}
                  </CDropdown.Item>
                );
              })}
            </CDropdown.Menu>
          </CDropdown.Toggle>
        </CDropdown>
      </div>
      <div className={style.tbl_container}>
        <DataTable
          keyField="Kode Referral"
          responsive
          columns={columns}
          data={state.search_list}
          progressPending={state.is_loading}
          progressComponent={<>Loading...</>}
          dense={true}
          noDataComponent={<>Tidak ada promo</>}
          pagination
          paginationTotalRows={state.total_data}
          paginationPerPage={state.total_per_page}
          paginationRowsPerPageOptions={[state.total_per_page]}
          customStyles={customStyles}
          onChangeRowsPerPage={changePerRows}
          onChangePage={changePage}
        />
      </div>
    </>
  );
}

export default ReferralSettingView;
