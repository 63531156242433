import { APIAuth } from "../utils/API"

export function getUserAccList() {
	return APIAuth.get(`useraccess`)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return []
		})
}

export function addUpdateUserAcc(payload) {
	const createFlag = [undefined, "", null].includes(payload?.id)
	let res = undefined
	if (createFlag) res = createUserAcc(payload)
	if (!createFlag) res = updateUserAcc(payload, payload.id)
	return res
}

function createUserAcc(payload) {
	const postData = new FormData()
	postData.append("name", payload.name)
	postData.append("email", payload.email)
	postData.append("handphone", `+62${payload.handphone.replaceAll("+62", "")}`)
	postData.append("password", payload.password)
	postData.append("password_confirmation", payload.conf_password)
	postData.append("etalase_id", JSON.stringify(payload.etalase))
	postData.append("sub_etalase_id", JSON.stringify(payload.subEtalase))
	postData.append("is_verified_email", 1)
	postData.append("is_verified_handphone", 1)
	postData.append("user_level_id", payload.user_level_id)
	return APIAuth.post(`useraccess`, postData)
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return undefined
		})
}

function updateUserAcc(payload, id) {
	const postdata = {}
	if ("etalase" in payload) postdata.etalase_id = JSON.stringify(payload?.etalase)
	if ("subEtalase" in payload) postdata.sub_etalase_id = JSON.stringify(payload?.subEtalase)
	if ("is_active" in payload) postdata.is_active = payload?.is_active
	if ("user_level_id" in payload) postdata.user_level_id = payload?.user_level_id
	console.log(postdata)
	return APIAuth.put(`useraccess/${id}`, { ...postdata })
		.then((res) => res.data)
		.catch((err) => {
			console.log(err)
			return undefined
		})
}
