import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Input } from "../components/CustomComponent/StyledComponent";
import CardProductLoading from "../components/Loading/CardProductLoading";
import SellerProductCardView from "../components/ProfileSeller/SellerProduct/SellerProductCardView";
import { getSellerProductList } from "../controller/sellerProductController";
import style from "../css/sellerProfile/createProduct/sellerProduct.module.css";
import { ADD_PRODUCT_SMENU } from "../utils/ConstantSeller";
import useDebounce from "../utils/useDebounce";
import SellerProductUploadModalView from "../components/ProfileSeller/SellerProduct/SellerProductUploadModalView";

const initState = {
  page: 1,
  last_page: undefined,
  isLoading: false,
  hasMores: false,
  toggle: false,

  search: "",
  product_list: [],
};
function ProductList(props) {
  const [state, setState] = useState(initState);
  const { isLoading, search } = state;
  const cg_flag = useSelector((s) => s.global.cgFlag);

  // ref
  const observer = useRef();
  const lastElRef = useCallback(
    (node) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && state.hasMores) {
          changeState({ isLoading: true });
          const page = state.page + 1;
          const temp = await getSellerProductList({ page, search });
          changeState({
            page: temp.current_page,
            product_list: [...state.product_list, ...temp.data],
            hasMores: temp.last_page > temp.current_page,
            last_page: temp.last_page,
            isLoading: false,
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [state.hasMores, state.page]
  );

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const handleSearch = (search) => changeState({ search });
  const handleCreate = () => window.location.href = `${ADD_PRODUCT_SMENU}`
  const loadPage = async (search = state.search) => {
    changeState({ isLoading: true, product_list: [], page: 1 });
    const product_list = await getSellerProductList({ search });
    changeState({
      page: product_list?.current_page ?? 1,
      product_list: product_list?.data ?? [],
      hasMores: (product_list?.current_page ?? 1) < (product_list?.last_page ?? 1),
      last_page: product_list?.last_page ?? 1,
      isLoading: false,
      toggle: false,
    });
  };
  const handleUpload = (toggle = !state.toggle, res = undefined) => {
    if (![undefined, null, ""].includes(res)) loadPage()
    changeState({ toggle })
  }

  // useEffect
  useEffect(() => { loadPage() }, []);
  useDebounce(() => { loadPage(search) }, 500, [search]);

  return (
    <>
      {state.toggle && <SellerProductUploadModalView toggle={state.toggle} popupHandler={handleUpload} />}
      <div className={style.container}>
        <div className={style.header}>
          <div className={style.filler_container}>
            <Input
              value={state.search}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Cari Produk"
            />
          </div>
          {!cg_flag && (
            <>
              <Button active onClick={() => handleCreate()}>
                <i className="bi bi-plus" /> <span>{`Tambah Produk`}</span>
              </Button>
              <Button active onClick={() => handleUpload()}>
                <i className="bi bi-upload" /> <span>{`Unggah Produk`}</span>
              </Button>
            </>
          )}
        </div>
        <div className={style.outer_list_container}>
          <ul className={style.list_container}>
            {state.product_list.map((data, idx) => {
              const sellerFlag = state.product_list.length === idx + 1
              const props = { key: `product_${idx}`, data }
              if (sellerFlag) props.lastElRef = lastElRef
              const Component = sellerFlag ? SellerProductCardView : SellerProductCardView;
              return <Component {...props} />
            })}
            {isLoading && <CardProductLoading />}
          </ul>
        </div>
      </div>
    </>
  );
}

export default ProductList;
