import React, { useCallback, useEffect, useState } from 'react';
import CustomModal, { HeaderModal } from '../../CustomComponent/CustomModal';
import { Button, Input } from '../../CustomComponent/StyledComponent';
import style from './user_access_form.module.css'
import { getProductEtalaseList } from '../../../controller/productEtalaseController';
import AccessFormView from './AccessFormView';
import Swal from 'sweetalert2';
import { emailRegex, passwordRegex, phoneRegex2 } from '../../../utils/Constants';
import { addUpdateUserAcc } from '../../../controller/userAccessController';
import Switcher from '../../CustomComponent/Switcher';

const errorState = {
    err_name: "",
    err_email: "",
    err_handphone: "",
    err_password: "",
    err_conf_password: "",
    err_acc: "",
}
const initState = {
    id: undefined, name: "", email: "", handphone: "", user_level_id: false,
    password: "", show_password: false, conf_password: "", show_conf_password: false,
    etalaseList: [], etalase: [], subEtalase: [],
    loading: false, pageLoading: false, ...errorState
}
function UserAccessForm({ data = undefined, toggle = false, handleCancel, handleSubmit, ...props }) {
    const editFlag = ![undefined, null, ''].includes(data)
    const title = `${editFlag ? 'Ubah' : 'Tambah'} User Akses`

    const [state, setState] = useState(initState)
    const { loading } = state;

    // function
    const changeState = (data) => setState((prev) => ({ ...prev, ...data }))
    const handleShowPass = (name) => changeState({ [name]: !state[name] })
    const changeHandler = (event) => {
        const { id, value } = event.target;
        changeState({ [id]: value, [`err_${id}`]: "" })
    }
    const changePhoneNumber = (event) => {
        const { id, value } = event.target;
        const phoneregex = /^$|^[^0\D][0-9]{0,13}$/;
        if (phoneregex.test(value) || value === "") {
            changeState({ [id]: value, err_handphone: "" });
        }
    }
    const handleEtalaseCheck = (event, sub_etalase) => {
        const { checked, value } = event.target;
        let etalase = [...state.etalase]
        let subEtalase = [...state.subEtalase]
        if (checked) etalase.push(value.toString());
        else etalase = etalase.filter(data => data !== value)
        sub_etalase.map((data) => {
            const id = (data.id);
            if (checked && !subEtalase.includes(id)) subEtalase.push(id);
            if (!checked) subEtalase.splice(subEtalase.indexOf(id), 1);
        })
        changeState({ etalase, subEtalase, err_acc: '' })
    }
    const handleSubEtalaseCheck = (event, data) => {
        const { checked, value } = event.target;
        const etalase_id = data.id
        let subEtalase = [...state.subEtalase]
        let etalase = [...state.etalase]
        if (checked) subEtalase.push(value.toString())
        if (checked && !etalase.includes(etalase_id)) etalase.push(etalase_id.toString())
        if (!checked) {
            subEtalase = subEtalase.filter(data => data !== value)
            const _data = data.sub_etalase.find((d) => { return subEtalase.includes(d.id) })
            if (!_data) etalase.splice(etalase.indexOf(data.id), 1)
        }
        changeState({ etalase, subEtalase, err_acc: '' })
    }
    const changeStatus = (event) => changeState({ user_level_id: event.target.checked });

    const showMsg = (icon, text) => {
        return Swal.fire({ icon, text, confirmButtonColor: "var(--main_color)" });
    }
    const validate = () => {
        let flag = true;
        const err = { ...errorState }
        const { name, handphone, email, password, conf_password } = state;
        const isEmpty = name === "" || handphone === "" || email === ""
        const emptyPass = (password === "" || conf_password === "") && !editFlag
        const isNotSame = password !== conf_password;
        const isNotRegex = !emailRegex.test(email) || !phoneRegex2.test(handphone) || (!passwordRegex.test(password) && !editFlag)
        const isNotAcc = state.etalase.length === 0 && state.subEtalase.length === 0

        if (isEmpty || isNotSame || isNotAcc || isNotRegex || emptyPass) flag = false
        if (name === "") err.err_name = `Nama tidak boleh kosong`
        if (handphone === "") err.err_handphone = `No.Telp tidak boleh kosong`
        if (handphone !== "" && !phoneRegex2.test(handphone)) err.err_handphone = `No.Telp tidak valid`
        if (email === "") err.err_email = `Email tidak boleh kosong`
        if (email !== "" && !emailRegex.test(email)) err.err_email = `Email tidak valid`
        if (password === "") err.err_password = `Kata sandi tidak boleh kosong`
        if (conf_password === "") err.err_conf_password = `Konfirmasi Kata sandi tidak boleh kosong`
        if ((password !== "" && conf_password !== "" && !passwordRegex.test(password))) {
            err.err_password = `Kata Sandi harus minimal 6 karakter serta mengandung 1 huruf kapital, 1 huruf kecil dan angka`
        }
        if ((password !== "" && conf_password !== "" && !passwordRegex.test(password)) && isNotSame) {
            err.err_password = 'Kata sandi tidak sama dengan Konfirmasi kata sandi'
        }
        if (isNotAcc) err.err_acc = 'User minimal harus memiliki 1 hak akses'
        changeState({ ...err })
        return flag
    }
    const _handleSubmit = async () => {
        if (!validate()) return showMsg('error', `Gagal me${editFlag ? 'ngubah' : 'nambah'} user`);
        changeState({ loading: true })
        // console.log({ ...state, user_level_id: state.user_level_id ? 9 : 8 })
        const res = await addUpdateUserAcc({ ...state, user_level_id: state.user_level_id ? 9 : 8 });
        if (!res) {
            return showMsg('error', `Gagal me${editFlag ? 'ngubah' : 'nambah'} user`)
                .then(() => changeState({ loading: false }))
        }
        return showMsg('success', `Berhasil me${editFlag ? 'ngubah' : 'nambah'} user`)
            .then(() => handleSubmit())
    }
    const _handleClose = () => { handleCancel() }
    const setSubEtalase = (sub_etalase = [], etalase = [], etalaseList) => {
        let tempSub = [...sub_etalase]
        if (etalase.length === 0 && sub_etalase === 0) return [];
        etalase.map((data) => {
            const sub = etalaseList.find(d => (d.id) === (data))?.sub_etalase ?? []
            const flag = sub.find((d) => { return sub_etalase.includes(d.id) })
            if (!flag) tempSub = [...tempSub, ...sub.map(d => d.id)]
        })
        return tempSub;
    }
    const initPage = useCallback(async () => {
        changeState({ pageLoading: true })
        const etalaseList = await getProductEtalaseList(1);
        const _data = {}
        if (editFlag) {
            _data.id = data?.user_id ?? undefined
            _data.name = data?.user.name ?? ""
            _data.email = data?.user.email ?? ""
            _data.handphone = (data?.user.handphone ?? "").replaceAll("+62", "")
            _data.etalase = data?.etalase_id ?? []
            _data.user_level_id = parseInt(data?.user?.user_level_id) === 9 ?? false
            _data.subEtalase = setSubEtalase(data?.sub_etalase_id ?? [], data?.etalase_id ?? [], etalaseList)
        }
        changeState({ ..._data, pageLoading: false, loading: false, etalaseList })
    })

    // useEffect
    useEffect(() => { initPage(); return () => setState(initState); }, [data])

    return (
        <CustomModal outsideClick toggle={toggle} size='l' modalProps={{ contentClassName: "" }}>
            <HeaderModal title={title} modalHandler={_handleClose} />
            <InputContainer name={'name'} label='Nama' err={state.err_name}>
                <Input className="form-control" id="name" name="name" value={state.name}
                    autoFocus={true} disabled={loading || editFlag} onChange={changeHandler}
                />
            </InputContainer>
            <div className={style.group_container}>
                <InputContainer name={'handphone'} label='No. Telp' err={state.err_handphone}>
                    <div className={style.input_hp_container}>
                        <span id="front">+62</span>
                        <Input value={state.handphone} id={'handphone'} disabled={loading || editFlag}
                            onChange={changePhoneNumber} maxLength={12}
                        />
                    </div>
                </InputContainer>
                <InputContainer name={'email'} label='E-mail' err={state.err_email}>
                    <Input className="form-control" id="email" name="email" value={state.email}
                        disabled={loading || editFlag} onChange={changeHandler}
                    />
                </InputContainer>
            </div>
            <div style={{ display: 'flex', gap: '0.5rem', paddingBlock: '0.5rem' }}>
                <label htmlFor='user_acc' style={{ fontWeight: '500' }}>User CS</label>
                <Switcher id={`user_acc`} toggle={state.user_level_id} onChangeToggle={changeStatus} />
            </div>
            {!editFlag && (
                <div className={style.group_container}>
                    <PasswordInput label={'Kata Sandi'} name={`password`}
                        value={state.password}
                        showPass={state.show_password}
                        handleShowPass={handleShowPass}
                        handleChange={changeHandler}
                        err={state.err_password}
                    />
                    <PasswordInput label={'Konfirmasi Kata Sandi'} name={`conf_password`}
                        value={state.conf_password}
                        showPass={state.show_conf_password}
                        handleShowPass={handleShowPass}
                        handleChange={changeHandler}
                        err={state.err_conf_password}
                    />
                </div>
            )}
            {!state.user_level_id && (
                <AccessFormView
                    loading={state.pageLoading}
                    list={state.etalaseList}
                    etalase={state.etalase}
                    subEtalase={state.subEtalase}
                    handleEtalaseCheck={handleEtalaseCheck}
                    handleSubEtalaseCheck={handleSubEtalaseCheck}
                />
            )}
            {state.err_acc !== "" && (
                <label style={{ margin: "0", fontSize: "0.7rem" }} className='error_txt'>
                    {state.err_acc}
                </label>
            )}
            {loading && <div>Loading...</div>}
            {!loading && (
                <div className={style.button_container}>
                    <Button active bg_color='red' onClick={_handleClose} disabled={loading}>
                        Tutup
                    </Button>
                    <Button active disabled={loading} onClick={_handleSubmit}>
                        Submit
                    </Button>
                </div>
            )}
        </CustomModal >
    );
}

export default UserAccessForm;

function InputContainer({ name, label = "", textHelper = "", err = "", ...props }) {
    return (
        <div className={`form-group ${style.input_container}`}>
            <label htmlFor={name} style={{ margin: "0", fontSize: "0.9rem" }}>
                {label}
            </label>
            {props.children}
            {(textHelper !== "" && err === "") && (
                <label style={{ margin: "0", fontSize: "0.7rem" }}>
                    {textHelper}
                </label>
            )}
            {err !== "" && (
                <label style={{ margin: "0", fontSize: "0.7rem" }} className='error_txt'>
                    {err}
                </label>
            )}
        </div>
    )
}

function PasswordInput({ name, value, label, textHelper = '', err = '', showPass, handleShowPass, handleChange }) {
    const icon = showPass ? "bi bi-eye-fill" : "bi bi-eye-slash-fill";

    return (
        <InputContainer name={name} label={label} textHelper={textHelper} err={err}>
            <div className="input-group">
                <Input
                    id={name} name={name} type={showPass ? "text" : "password"}
                    className={`form-control`} value={value} onChange={handleChange}
                />
                <div className="input-group-append">
                    <span className={`input-group-text`} id="basic-addon">
                        <i className={icon} onClick={() => handleShowPass(`show_${name}`)} />
                    </span>
                </div>
            </div>
        </InputContainer>
    );
}