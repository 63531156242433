import React from "react";
import style from "../../css/shareCard.module.css";
import { isEmpty } from "../../controller/globalController";

function ShareCardView({ toggle, link, button, text = "", position, ...props }) {
  const base_link = `https://www.share.1toko.com?${link}`;
  const share_text = text.replaceAll("\\n", "%0a");
  var position_style = isEmpty(position) ? style.left : style[position];
  var show_style = style[toggle ? "down" : "up"];
  const list_style = `${style.list_container} ${show_style} ${position_style}`;
  const facebook_link = `https://www.facebook.com/sharer.php?u=${base_link}&quote=${share_text}`;
  const twiter_link = `https://www.twitter.com/intent/tweet?url=${base_link}&text=${share_text}`;
  const line_link = `https://lineit.line.me/share/ui?url=${base_link}&text=${share_text}`;
  const whatsapp_link = `https://api.whatsapp.com/send?text=${share_text}%0a${base_link}`;

  const handleLink = (link) => (window.location.href = link);

  return (
    <div className={`${style.container}`}>
      <div>{button}</div>
      <ul className={list_style}>
        <li>
          <i
            className="bi bi-facebook"
            onClick={() => handleLink(facebook_link)}
            style={{ backgroundColor: "#3b5998" }}
          />
        </li>
        <li>
          <i
            className="bi bi-twitter"
            onClick={() => handleLink(twiter_link)}
            style={{ backgroundColor: "#00acee" }}
          />
        </li>
        <li>
          <i className={style.line} onClick={() => handleLink(line_link)} />
        </li>
        <li>
          <i
            className="bi bi-whatsapp"
            onClick={() => handleLink(whatsapp_link)}
            style={{ backgroundColor: "#25D366" }}
          />
        </li>
        <li>
          <i
            className="bi bi-link-45deg"
            onClick={() => navigator.clipboard.writeText(base_link)}
            style={{ backgroundColor: "#FFE500" }}
          />
        </li>
      </ul>
    </div>
  );
}

export default ShareCardView;
