import React, { useEffect, useRef, useState } from "react";
import style from "../../../../css/varianForm/inputVarian.module.css";
import { regexName } from "../../../../utils/Constants";

function InputVarianView({ varian_list = [], disabled, onChange, ...props }) {
  const [state, setState] = useState({
    list: varian_list,
    varian: "",
  });
  const ref = useRef();
  const disable_style = disabled ? style.disabled : "";
  const container_style = `${style.container} ${disable_style}`;
  const input_style = `${style.input_container}`;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const checkList = (value) => state.list.find((d) => d === value);
  const changeVarian = (e) => {
    var varian = e.target.value;
    if (regexName.test(varian) || varian === "") changeState({ varian });
  };
  const handleKeyDown = (e) => {
    var keyCode = e.which || e.keyCode;
    if (keyCode === 13 && state.varian !== "") {
      e.preventDefault();
      handleAddVarian();
    }
  };
  const handleDelete = async (idx) => {
    const temp_list = [...state.list];
    const deleted = temp_list[idx];
    temp_list.splice(idx, 1);
    changeState({ list: temp_list });
    await props.onDelete(props.name, deleted, temp_list);
  };
  const handleAddVarian = async () => {
    const temp_list = [...state.list];
    const value = state.varian;
    if (checkList(value) === undefined) {
      temp_list.push(value);
      changeState({ varian: "", list: temp_list });
      await onChange(props.name, temp_list);
    }
  };

  // useEffect
  // useEffect(() => , [state.list]);
  useEffect(() => { changeState({ list: varian_list }) }, [varian_list]);

  return (
    <div className={container_style} onClick={() => ref.current.focus()}>
      {state.list.map((v, idx) => {
        const key = `v_${idx}`;
        return (
          <div key={key} className={style.varian_card}>
            <span>{v}</span>
            <i
              className={`bi bi-x-lg`}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(idx);
              }}
            />
          </div>
        );
      })}

      <div className={input_style}>
        <input
          value={state.varian}
          onChange={(e) => changeVarian(e)}
          onKeyDown={(e) => handleKeyDown(e)}
          onBlur={() => changeState({ varian: "" })}
          maxLength={15}
          ref={ref}
          disabled={disabled}
          autoCorrect="false"
        />
      </div>
    </div>
  );
}

export default InputVarianView;
