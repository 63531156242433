import React, { useMemo, useState } from "react";
import { createUpdateStore, getColorList } from "../../../controller/sellerProfileController";
import style from "../../../css/sellerProfile/sellerProfile.module.css";
import { BASE_URL_IMG, regexName } from "../../../utils/Constants";
import { Button, Input } from "../../CustomComponent/StyledComponent";
import InputImage from "../../CustomComponent/InputImage";
import ColorInputView from "../../CustomComponent/ColorInputView";
import SelllerImageCropModalView from "./SelllerImageCropModalView";
import Swal from "sweetalert2";
import { globalStorage } from "../../../utils/Storage";
import { getUserProfile } from "../../../controller/authController";
import SellerProfileLoading from "../../Loading/SellerProfileLoading";

const initState = {
  sellerData: {},
  store_name: "",
  subdomain: "",
  color: "",
  desc_title: "",
  desc: "",
  slogan: "",

  // image
  logo: "",
  banner1: "",
  banner2: "",
  banner3: "",
  banner4: "",

  // prev
  prev_logo: "",
  prev_banner1: "",
  prev_banner2: "",
  prev_banner3: "",
  prev_banner4: "",

  // param
  color_list: [],

  // crop
  crop_for: "",
  crop_img: undefined,
  crop_toggle: false,
  crop_rounded: false,
  crop_title: "",

  // error
  err_name: "",

  // other
  is_edit: false,
  is_loading: false,
};

function SellerProfileMain() {
  const [state, setState] = useState(initState);
  const { color, is_edit, is_loading } = state;

  // function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeImage = (event) => {
    const crop_img = event.target.files[0];
    const crop_for = event.target.name;
    const crop_title = crop_for === "logo" ? "Gambar Logo" : "Gambar Banner";
    const crop_rounded = crop_for === "logo";
    const crop_toggle = true;
    changeState({ crop_img, crop_toggle, crop_for, crop_title, crop_rounded });
  };
  const changeText = (event) => {
    const { value, name } = event.target;
    const data = { [name]: value };
    changeState(data);
  };
  const changeName = (text) => {
    if (regexName.test(text) || text === "") {
      changeState({ store_name: text, err_name: "" });
    }
  };
  const deleteImage = (name) => {
    changeState({ [name]: null, [`prev_${name}`]: null });
  };
  const handleCropImg = async (img_res) => {
    const img = img_res?.img ?? state[state.crop_for];
    const prev_img = img_res?.img_url ?? state[state.crop_for];
    changeState({
      crop_img: undefined,
      crop_toggle: false,
      crop_rounded: false,
      crop_title: "",
      crop_for: "",
      [state.crop_for]: img,
      [`prev_${state.crop_for}`]: prev_img,
    });
  };

  const submitValidate = async () => {
    let temp = true;
    let err = { err_name: "" };
    if (state.store_name === "") err.err_name = "Nama toko tidak boleh kosong";
    changeState(err);

    return temp;
  };

  const handleCancel = () => {
    changeState({ ...state.sellerData, is_edit: false });
  };
  const handleSubmit = async () => {
    const data = { ...state };
    if (await submitValidate()) {
      if (data.banner1 || data.banner2 || data.banner3) {
        changeState({ is_loading: true });
        const res = await createUpdateStore(data, "update");
        if (res) {
          const old_data = globalStorage.getItem("SD");
          if (old_data?.id === res?.id) globalStorage.setItem("SD", res);
          const sellerData = initSellerData(res);

          const root = document.querySelector(":root");
          root.style.setProperty("--main_color", sellerData?.color?.code ?? "#583985");
          changeState({ is_loading: false, sellerData, is_edit: false });
        } else changeState({ is_loading: false });
      } else {
        Swal.fire({ icon: "error", text: "Minimal harus memiliki 1 banner" });
      }
    }
  };
  const initSellerData = (seller, color_list = state.color_list) => {
    var sellerData = {};
    const base_logo = `${BASE_URL_IMG}100/100/${seller.id}/`;
    const base_banner = `${BASE_URL_IMG}1200/300/${seller.id}/`;
    let banner1 = seller.banner[0]?.image ?? null;
    let banner2 = seller.banner[1]?.image ?? null;
    let banner3 = seller.banner[2]?.image ?? null;
    let banner4 = seller.banner[3]?.image ?? null;
    let logo = seller.logo === "" ? null : seller.logo;

    sellerData = {
      store_name: seller.name,
      subdomain: seller.subdomain,
      color: color_list.find((d) => d.id === seller.color_id),
      logo,
      banner1,
      banner2,
      banner3,
      banner4,
      prev_logo: logo !== null ? `${base_logo}${logo}` : null,
      prev_banner1: banner1 !== null ? `${base_banner}${banner1}` : null,
      prev_banner2: banner2 !== null ? `${base_banner}${banner2}` : null,
      prev_banner3: banner3 !== null ? `${base_banner}${banner3}` : null,
      prev_banner4: banner4 !== null ? `${base_banner}${banner4}` : null,
      desc_title: seller.title ?? "",
      desc: seller.description ?? "",
      slogan: seller.slogan ?? "",
    };

    return sellerData;
  };

  const initPage = async () => {
    changeState({ is_loading: true });
    const userData = await getUserProfile();
    const color_list = await getColorList();
    const { seller } = userData;
    var sellerData = {};
    if (seller) sellerData = initSellerData(seller, color_list);
    changeState({ color_list, ...sellerData, sellerData, is_loading: false });
  };

  // useEffect
  useMemo(() => {
    initPage();
    return () => setState({ initPage });
  }, []);

  // view
  return (
    <>
      {state.crop_toggle && (
        <SelllerImageCropModalView
          round={state.crop_rounded}
          toggle={state.crop_toggle}
          title={state.crop_title}
          baseImg={state.crop_img}
          popupHandler={handleCropImg}
        />
      )}
      <div className={style.container}>
        {is_loading && <SellerProfileLoading />}
        {!is_loading && (
          <>
            {/* name and subdomain */}
            <div className={style.part_container}>
              <div className="form-group">
                <label className={style.title}>Nama Toko</label>
                <Input
                  value={state.store_name}
                  onChange={(e) => changeName(e.target.value)}
                  disabled={!is_edit}
                />
                <label className="error_txt">{state.err_name}</label>
              </div>
              <div className={style.subdomain_container}>
                <label className={style.title}>Nama Domain</label>
                <div className={style.text_part}>
                  {/* <label>{`${state.subdomain}${DOMAIN}`}</label> */}
                  <label className={style.subdomain}>
                    {`${state.subdomain}.1toko.com`}
                  </label>
                </div>
              </div>
            </div>
            {/* note */}
            <div className={style.change_subdomain_container}>
              <label className={style.note}>
                {`*) Pastikan Nama Domain Anda benar. Jika Anda ingin mengubah Nama Domain Anda setelah terdaftar,
             harap mengajukan perubahan Nama Domain.`}
              </label>
              <label className={style.subdomain_change_btn} onClick={() => { }}>
                {`Ajukan ubah Nama Domain`}
              </label>
            </div>
            <div className={style.part_container}>
              {/* left part */}
              <div>
                {/* logo and color */}
                <div className={style.small_part_container}>
                  <div className={style.logo_container}>
                    <label className={style.title}>Logo Toko</label>
                    <InputImage
                      round={true}
                      name="logo"
                      btn_text={"Pilih Gambar"}
                      img_link={state.prev_logo}
                      changeImage={changeImage}
                      deleteImage={deleteImage}
                      is_edit={is_edit}
                    />
                  </div>
                  <ColorInputView
                    color={color}
                    color_list={state.color_list}
                    changeColor={changeState}
                    is_edit={is_edit}
                  />
                </div>
                {/* desc title */}
                <div className="form-group mt-2 mb-2">
                  <div className={style.group_title}>
                    <label className={style.title}>Judul Toko</label>
                    <label className={style.count_text}>
                      {` ${state.desc_title?.length ?? 0} / 150`}
                    </label>
                  </div>
                  <Input
                    value={state.desc_title}
                    name="desc_title"
                    onChange={(e) => changeText(e)}
                    maxLength={150}
                    disabled={!is_edit}
                  />
                </div>
                {/* store slogan */}
                <div className={style.input_text_area}>
                  <div className={style.group_title}>
                    <label className={style.title}>Slogan Toko</label>
                    <label className={style.count_text}>
                      {` ${state.slogan?.length} / 85`}
                    </label>
                  </div>
                  <textarea
                    name="slogan"
                    value={state.slogan}
                    onChange={(e) => changeText(e)}
                    maxLength={85}
                    style={{ minHeight: "10px" }}
                    disabled={!is_edit}
                  />
                </div>
                {/* store desc */}
                <div className={style.input_text_area}>
                  <div className={style.group_title}>
                    <label className={style.title}>Deskripsi Toko Anda</label>
                    <label className={style.count_text}>
                      {` ${state.desc?.length ?? 0} / 1500`}
                    </label>
                  </div>
                  <textarea
                    name="desc"
                    value={state.desc}
                    onChange={(e) => changeText(e)}
                    maxLength={1500}
                    disabled={!is_edit}
                  />
                </div>
                <div className={style.banner_container}>
                  <label className={style.title}>Banner 1</label>
                  <InputImage
                    name="banner1"
                    btn_text={"Pilih Gambar"}
                    img_link={state.prev_banner1}
                    changeImage={changeImage}
                    deleteImage={deleteImage}
                    is_edit={is_edit}
                  />
                </div>
              </div>
              {/* right part */}
              <div>
                <div className={style.banner_container}>
                  <label className={style.title}>Banner 2</label>
                  <InputImage
                    name="banner2"
                    btn_text={"Pilih Gambar"}
                    img_link={state.prev_banner2}
                    changeImage={changeImage}
                    deleteImage={deleteImage}
                    is_edit={is_edit}
                  />
                </div>
                <div className={style.banner_container}>
                  <label className={style.title}>Banner 3</label>
                  <InputImage
                    name="banner3"
                    btn_text={"Pilih Gambar"}
                    img_link={state.prev_banner3}
                    changeImage={changeImage}
                    deleteImage={deleteImage}
                    is_edit={is_edit}
                  />
                </div>
                <div className={style.banner_container}>
                  <label className={style.title}>Banner 4</label>
                  <InputImage
                    name="banner4"
                    btn_text={"Pilih Gambar"}
                    img_link={state.prev_banner4}
                    changeImage={changeImage}
                    deleteImage={deleteImage}
                    is_edit={is_edit}
                  />
                </div>
              </div>
            </div>
            <div className={style.btn_container}>
              {!is_edit && (
                <Button active onClick={() => changeState({ is_edit: true })}>
                  Ubah
                </Button>
              )}
              {is_edit && (
                <>
                  <Button onClick={() => handleCancel()}>Batal</Button>
                  <Button active onClick={() => handleSubmit()}>
                    Simpan
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default SellerProfileMain;
