import React, { useEffect, useState } from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { InputLine } from "../CustomComponent/StyledComponent";
import style from "../../css/location/locationInput.module.css";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { geocodeByAddress } from "react-places-autocomplete";
import Loading from "../Loading/Loading";
import useDebounce from "../../utils/useDebounce";

function LocInputAutoComplete(props) {
  const google = window.google;
  const { _loc, address, getAddress, error } = props;
  const [state, setState] = useState({
    _location: "",
    search: "",
    lat: 0,
    lng: 0,
    show: true,
  });
  const placeholder = "Nama Jalan / Gedung";
  const { _location, lat, lng, show } = state;
  let center = new google.maps.LatLng(lat, lng);
  const defaultBounds = {
    north: center.lat() + 0.1,
    south: center.lat() - 0.1,
    east: center.lng() + 0.1,
    west: center.lng() - 0.1,
  };

  //   useEffect
  const initComponent = async () => {
    let data = { _location: address, lat: props.lat, lng: props.lng };
    if (_loc && address === "") {
      const { province, subProvince, district, subDistrict, zip } = _loc;
      const tempLoc = `${_location}, ${province}, ${subProvince}, ${district}, ${subDistrict}, ${zip}`;
      const temp = await geocodeByAddress(tempLoc);
      const { lat, lng } = temp[0].geometry.location;
      data.lat = lat();
      data.lng = lng();
    }
    changeState(data);
  };
  useEffect(() => { initComponent() }, [_loc]);
  useDebounce(() => getAddress(_location, { lat, lng }), 500, [lat, lng]);

  //   function
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const handleSelect = async (address, placeId) => {
    const _address = addressFormater(address);
    let data = { _location: _address, lat: lat, lng: lng };
    if (placeId) {
      const temp = await geocodeByPlaceId(placeId);
      const location = temp[0].geometry.location;
      data.lat = location.lat();
      data.lng = location.lng();
    } else {
      const new_address = _address + `, ${_loc.subDistrict}`;
      let temp1 = await geocodeByAddress(new_address);
      data.lat = temp1[0].geometry.location.lat();
      data.lng = temp1[0].geometry.location.lng();
    }
    changeState(data);
  };

  const handleChange = async (data) => {
    changeState({ _location: data, search: data, show: true });
  };

  const onBlur = async () => {
    let data = { lat: lat, lng: lng };
    if (show && address !== _location) {
      const new_address = `${_location}, ${_loc.subDistrict}, ${_loc.zip}`;
      let temp1 = await geocodeByAddress(new_address);
      data.lat = temp1[0].geometry.location.lat();
      data.lng = temp1[0].geometry.location.lng();
      getAddress(_location, { lat: lat, lng: lng });
    }
    changeState({ show: false, ...data });
  };

  const onError = (status, clearSuggestions) => clearSuggestions();
  const addressFormater = (data) => {
    const temp = data.split(",");
    return `${temp[0]}${temp[1] ? `,${temp[1]}` : ""}${temp[2] ? `,${temp[2]}` : ""
      }${temp[3] ? `,${temp[3]}` : ""}`;
  };

  return (
    <PlacesAutocomplete
      value={state.search + `${_loc?.subDistrict} ${_loc?.zip}`}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={onError}
      searchOptions={{
        bounds: defaultBounds,
        strictBounds: true,
        componentRestrictions: { country: ["ID"] },
        types: ["address"],
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative" }}>
          <div className={`line_input_container ${!_loc ? "disabled" : ""}`}>
            <label htmlFor="_address">Alamat</label>
            <InputLine
              id="_address"
              {...getInputProps({ placeholder })}
              disabled={!_loc}
              onBlur={() => onBlur()}
              value={_location}
              maxLength={250}
            />
            {error !== "" && <label className="error_txt">{error}</label>}
          </div>
          <div
            className={`${style.container_menu} ${suggestions.length > 0 && show ? style.show : style.hide
              }`}
          >
            <div
              className={`${style.menu_item_container}`}
              style={{ paddingTop: "0.5rem" }}
            >
              {loading && <Loading />}
              {suggestions.map((data, idx) => {
                const className = `${style.item}`;
                data.description = addressFormater(data.description);
                return (
                  <div
                    {...getSuggestionItemProps(data, { className })}
                    key={`_${idx}`}
                  >
                    {data.description}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}

export default LocInputAutoComplete;
