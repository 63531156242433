import React, { useEffect, useMemo, useState } from "react";
import {
  getPayoutList,
  tableStyle,
} from "../../../controller/payoutTransactionController";
import style from "../../../css/sellerProfile/payoutTransaction.module.css";
import { Button, InputLine } from "../../CustomComponent/StyledComponent";
import { formatCurrency } from "../../../controller/globalController";
import DataTable from "react-data-table-component";
import InvoiceDetailModalView from "./InvoiceDetailModalView";
import { getDetailPrint } from "../../../controller/transactionController";
import Swal from "sweetalert2";
import PayoutLoading from "../../Loading/PayoutLoading";
import SubmitPayoutModal from "./SubmitPayoutModal";
import { getBankAccountList } from "../../../controller/bankAccountController";
import useDebounce from "../../../utils/useDebounce";

const initState = {
  list: [],
  bank_list: [],
  selected_data: [],
  selected_inv: undefined,

  // table
  search: "",
  current_page: 1,
  total_per_page: 5,
  total_data: 1,

  // other
  select_all: false,
  is_loadingPage: false,
  is_loading: false,
  toggle: false,
  toggle1: false,
};

function PayoutTransactionMain(props) {
  const [state, setState] = useState(initState);
  const { current_page, search, total_per_page } = state;

  const selected_flag = useMemo(() => {
    const temp = state.list.find((d) => parseInt(d.selected) === 1);
    return temp ? true : false;
  }, [state.list]);

  // fucntion
  const changeState = (data) => setState((prev) => ({ ...prev, ...data }));
  const changeSearch = (search) => changeState({ search });
  const changePerRows = (total_per_page) => changeState({ total_per_page });
  const changePage = (current_page) => changeState({ current_page });
  const handleSubmitPopup = (toggle1 = !state.toggle1, process = "CANCEL") => {
    if (process === "CANCEL") changeState({ toggle1 });
    if (process === "FINISH") loadPage(1, state.total_per_page);
  };
  const handleSubmit = () => {
    const selected_data = state.list.filter((d) => parseInt(d.selected) === 1);
    changeState({ toggle1: true, selected_data });
  };

  // page function
  const loadPage = async (page = 1, limit = state.total_per_page) => {
    changeState({ is_loading: true });
    const list = await getPayoutList("", "", search, [0], page, limit);
    const { data, current_page, total } = list;
    changeState({
      list: data,
      current_page,
      toggle1: false,
      total_data: total,
      select_all: false,
      is_loading: false,
    });
  };

  const initPage = async () => {
    changeState({ is_loadingPage: true });
    const list = await getPayoutList("", "", search, [0], 1, total_per_page);
    const bank_list = await getBankAccountList();
    const { data, current_page, total } = list;
    changeState({
      list: data,
      bank_list,
      current_page,
      total_data: total,
      select_all: false,
      is_loadingPage: false,
    });
  };

  // table function
  const checkSelected = (list) => {
    const temp = list.find((d) => parseInt(d.selected) === 0);
    return temp ? false : true;
  };
  const handleSelectAll = () => {
    let listUpdate = [...state.list];
    listUpdate.map((d) => {
      if (parseInt(d.status_id) === 0) {
        d.selected = !state.select_all ? 1 : 0;
      }
    });
    changeState({ list: listUpdate, select_all: !state.select_all });
  };
  const handleSelect = (row) => {
    let listUpdate = [...state.list];
    let invoice_data = listUpdate.find(
      (data) => data.invoice_id === row.invoice_id
    );
    invoice_data.selected = parseInt(invoice_data.selected) === 1 ? 0 : 1;
    const select_all = checkSelected(listUpdate);
    changeState({ list: listUpdate, select_all });
  };
  const handlePopup = async (
    toggle = !state.toggle,
    selected_inv = undefined
  ) => {
    if (toggle && selected_inv) {
      Swal.fire({
        didOpen: () => Swal.showLoading(),
        allowOutsideClick: false,
      });
      selected_inv = await getDetailPrint(selected_inv);
      Swal.close();
    }
    changeState({ toggle, selected_inv });
  };

  // useEffect
  useEffect(() => { initPage() }, []);
  useEffect(() => {
    if (state.list.length > 0) loadPage(current_page, total_per_page);
  }, [current_page, total_per_page]);
  useDebounce(() => loadPage(current_page, total_per_page), 500, [search]);

  // table props
  const headerCheckbox = () => {
    const icon = `bi bi-${state.select_all ? "check-square-fill" : "square"} ${style.icon
      }`;
    return <i className={icon} onClick={() => handleSelectAll()} />;
  };
  const itemCheckbox = (row) => {
    const flag = parseInt(row.selected) === 1;
    const icon = `bi bi-${flag ? "check-square-fill" : "square"} ${style.icon}`;
    return <i className={icon} onClick={() => handleSelect(row)} />;
  };
  const columns = [
    {
      name: headerCheckbox(),
      grow: 0,
      minWidth: "30",
      cell: (row) => itemCheckbox(row),
    },
    {
      name: "No. Invoice",
      selector: (row) => row.invoice_id,
      minWidth: "200px",
      format: (row) => (
        <a
          className={style.link}
          onClick={() => handlePopup(true, row.invoice_id)}
        >
          {row.invoice_id}
        </a>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Tanggal Transaksi",
      selector: (row) => row.date,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status Penarikan Dana",
      selector: (row) => row.status,
      style: { margin: "0" },
      sortable: true,
      wrap: true,
    },
    {
      name: "Nominal",
      selector: (row) => row.paid_amount,
      format: (row) => formatCurrency(row.paid_amount),
      sortable: true,
      right: true,
    },
  ];

  if (state.is_loadingPage) return <PayoutLoading />;
  return (
    <>
      {state.toggle && state.selected_inv && (
        <InvoiceDetailModalView
          toggle={state.toggle}
          invoice={state.selected_inv}
          popupHandler={handlePopup}
        />
      )}
      {state.toggle1 && (
        <SubmitPayoutModal
          toggle={state.toggle1}
          popupHandler={handleSubmitPopup}
          bankList={state.bank_list}
          selectedData={state.selected_data}
        />
      )}
      <div className={style.container}>
        <div className={style.filter_container}>
          <InputLine
            value={state.search}
            onChange={(e) => changeSearch(e.target.value)}
            placeholder="Cari Data"
          />
          <Button
            active
            onClick={() => handleSubmit()}
            disabled={!selected_flag}
            style={{ maxWidth: "200px" }}
          >
            Permintaan Penarikan Dana
          </Button>
        </div>
        <div className={style.table_container}>
          <DataTable
            keyField="payout"
            responsive
            fixedHeader
            fixedHeaderScrollHeight="400px"
            columns={columns}
            data={state.list}
            progressPending={state.is_loading}
            progressComponent={<>Loading...</>}
            dense={true}
            noDataComponent={<>Tidak ada data</>}
            pagination
            paginationServer
            paginationTotalRows={state.total_data}
            paginationPerPage={state.total_per_page}
            paginationRowsPerPageOptions={[5, 10, 20]}
            customStyles={tableStyle}
            onChangeRowsPerPage={changePerRows}
            onChangePage={changePage}
          />
        </div>
      </div>
    </>
  );
}

export default PayoutTransactionMain;
